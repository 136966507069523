.upcoming-matches {
  // background-color: #143cdb;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 0 0;

  * {
    font-family: Arial, Helvetica, sans-serif;
  }

  @media (min-width: 600px) {
    flex-direction: row;
  }

  .matches {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    overflow-x: scroll;
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
      display: none;
    }

    .match-card {
      min-width: 200px;
      background-color: rgba($color: #0d1e62, $alpha: 1);
      border-radius: 10px;
      margin-right: 8px;
      padding: 8px;
      display: flex;
      justify-content: flex-start;
      gap: 8px;
      flex-direction: column;

      &.live {
        background-color: rgba($color: #ff0000, $alpha: 1);
      }

      @media (min-width: 600px) {
        flex-direction: column;
        align-items: flex-start;
      }

      .info {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: 100%;
        border-bottom: 1px solid rgba($color: #fff, $alpha: 0.3);
        padding-bottom: 4px;
        margin-bottom: 2px;

        span {
          color: rgba($color: #ffffff, $alpha: 0.8);
          font-size: 16px;
          font-weight: bold;
        }
      }

      .teams {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;
      }

      .team {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        width: 100%;

        .logo {
          width: 20px;
          height: 20px;
          background: #ccc;
          border-radius: 100%;
          overflow: hidden;
          display: flex;

          img {
            width: 20px;
            height: 20px;
          }
        }

        span {
          color: #fff;
          font-size: 16px;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 16px;
          font-weight: bold;

          &.team-name {
            flex: 1 0 auto;
            font-weight: normal;
          }
        }
      }
    }
  }
}

.group-standings {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  font-size: 16px;

  .groups {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    width: 100%;

    &::-webkit-scrollbar {
      display: none;
    }

    .group-card {
      background-color: rgb(255, 255, 255);
      border-radius: 10px;
      padding: 0;
      width: 100%;

      .teams {
        display: flex;
        flex-direction: column;
        border: 1px solid #eee;
        border-radius: 10px;
        .team-row {
          display: flex;
          border-bottom: 1px solid #eee;
          &:last-child {
            border: none;
          }
          padding: 10px 0;
          .team-info {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 200px;
            gap: 10px;
            padding-left: 8px;

            img {
              width: 20px;
              height: 20px;
              object-fit: contain;
            }
          }
          .header {
            span {
              display: none;
            }
            .mobile-letter {
              display: block;
            }
            @media (min-width: 700px) {
              span {
                display: block;
              }
              .mobile-letter {
                display: none;
              }
            }
          }
          .team-stats {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            gap: 30px;
            .hide-mobile {
              display: none;
            }
            @media (min-width: 700px) {
              .hide-mobile {
                display: block;
              }
            }
            span {
              color: black;
              font-size: 16px;
              min-width: 30px;
              text-align: center;
              @media (min-width: 700px) {
                flex: 1;
              }
            }
          }
        }
      }
    }
  }
}

.group-detailed {
  padding: 20px;
  .group-table {
    h2 {
    }
    table {
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;
      thead {
        background-color: rgb(72, 163, 208);
        color: white;
        th {
          padding: 10px;
        }
      }
      tbody {
        tr {
          border-bottom: 1px solid rgb(133, 132, 132);
          &:last-child {
            border: none;
          }
          td {
            padding: 10px;
          }
        }
      }

      img {
        width: 30px;
        height: 30px;
        object-fit: contain;
      }
      .team {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        padding-left: 10px;
      }
    }
  }
}

.matches-calendar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;

  .matches {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    width: 100%;

    .match {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 8px;
      width: 100%;
      padding-bottom: 8px;
      border-bottom: 1px solid #eee;

      .team {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        flex: 1 0 calc(50% - 40px - 8px);

        .logo {
          display: flex;
          width: 30px;
          height: 30px;
          background: #ccc;
          border-radius: 100%;
          overflow: hidden;

          img {
            display: flex;
            width: 100%;
            height: 100%;
          }
        }

        &:first-of-type {
          justify-content: flex-end;
        }
      }

      .res {
        display: flex;
        flex-direction: column;
        font-weight: bold;
        width: 80px;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.euro-wrapper {
  * {
    font-family: Arial, Helvetica, sans-serif;
    padding: 0;
    margin: 0;
  }

  h2 {
    padding-bottom: 16px;
  }

  display: flex;
  width: 100%;
  padding: 0;
  margin: 0;
  flex-direction: column;

  .euro-inner {
    display: flex;
    width: 100%;
    padding: 0;
    margin: 0;
    flex-direction: column;
    gap: 32px;

    .matches-calendar {
      width: 100%;
      width: calc(100% - 32px);
    }

    // @media (min-width: 1000px) {
    //   flex-direction: row;
    //   flex-wrap: wrap;

    //   .group-standings {
    //     width: 50%;
    //     width: calc(50% - 32px);
    //   }
    //   .matches-calendar {
    //     width: 50%;
    //     width: calc(50% - 32px);
    //   }
    // }
  }
}
